import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

// utils
import appLocalStorage from '@src/lib/storage/localStorage';

// atoms
import {
  asyncSetAuthInfoUser as actionAuthInfoUser,
  asyncSetAuthInfoIsVerification as actionSetAuthInfoIsVerification,
} from '@src/atoms/user';

// Route
import { routerRegex } from '@src/route/routeConfig';

// Utils
import { goToAppPageWithHref } from '@src/utils/helper';

// API
import { AUTH_KEY } from '@src/lib/axios/apiClient';
import * as api from '@src/common/api';

interface IAuthToken {
  accessToken: string;
  refreshToken: string;
}

const loginUser = (userData: IAuthToken) => {
  appLocalStorage.set({ [AUTH_KEY]: userData });
};

export default function KakaoAuthRouter() {
  const setAuthInfoUser = useSetAtom(actionAuthInfoUser);
  const setAuthInfoIsVerification = useSetAtom(actionSetAuthInfoIsVerification);

  useEffect(() => {
    (async () => {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const code = params.get('code');
      const state = params.get('state');

      // login return uri
      const pageReturnURI = state;

      if (!code) {
        goToAppPageWithHref('로그인에 실패하였습니다.', routerRegex.main.path);
      }

      try {
        // code가 들어오면 access_token을 받는다.
        const kakaoAuth = await api.getKakaoAuthToken(code);
        if (kakaoAuth.data) {
          const accessToken = kakaoAuth.data.access_token;

          // code가 들어오면 API를 호출해서 CCCV에 로그인을 한다.
          const cccvLogin = await api.cccvLogin('kakao', {
            access_token: accessToken,
          });

          if (cccvLogin && cccvLogin.data) {
            // 스토리지에 token 저장
            loginUser({
              accessToken: cccvLogin.data.accessToken,
              refreshToken: cccvLogin.data.refreshToken,
            });

            // User Info Atoms에 저장
            setAuthInfoUser();
            // User IsVerification Atoms에 저장
            setAuthInfoIsVerification();
          }

          window.location.href = pageReturnURI
            ? decodeURIComponent(pageReturnURI)
            : routerRegex.main.path;
        }
      } catch (err) {
        goToAppPageWithHref('로그인에 실패하였습니다.', routerRegex.main.path);
      }
    })();
  }, []);

  return <div>...Loading</div>;
}
