import { getBuyableToken } from '@src/api/productDetail';
import { shuffleArray } from '@src/utils/helper';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

export const buyable = async ({ params: { gId } }: LoaderFunctionArgs) => {
  const { data } = await getBuyableToken(gId);
  const buyableItemArray = data.nftTokens.filter(
    (item: any) =>
      data.creator_id === item.owner_id &&
      item.isActive &&
      item.paymentProductType !== null &&
      (item.paymentProductType === 'nftSale' ||
        item.paymentProductType === 'nftAuction'),
  );
  const notSaleItemArray = data.nftTokens.filter(
    (item: any) => data.creator_id === item.owner_id && item.isActive,
  );
  const activeItemArray = data.nftTokens.filter((item: any) => item.isActive);

  const randomBuyAbleItem = shuffleArray(buyableItemArray)[0];
  const randomNotSaleItem = shuffleArray(notSaleItemArray)[0];
  const randomActiveItem = shuffleArray(activeItemArray)[0];

  if (randomBuyAbleItem) {
    return redirect(`/nft/${randomBuyAbleItem._id}`);
  }
  if (randomNotSaleItem) {
    return redirect(`/nft/${randomNotSaleItem._id}`);
  }
  if (randomActiveItem) {
    return redirect(`/nft/${randomActiveItem._id}`);
  }
  return redirect(`/`);
};
