import { styled } from '@mui/system';

export const CommonMain = styled('div')({
  display: 'flex',
  fontSize: 0,
  flex: '1 1 0',
});

export const CommonMainInner = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  flex: '1 1 0',
  // maxWidth: theme.breakpoints.values.xxl,
}));
