import { StrictMode, Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// Route
import { router } from '@src/route/routeConfig';

// i18n
import { initialize as i18nInit } from '@src/lib/i18n/react-i18next';

import reportWebVitals from './reportWebVitals';
import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
};

// Init i18n
i18nInit();

// Init GTM
TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container as Element);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );
root.render(
  // devmode에서 렌더링 두번 실행 방지 https://reactjs.org/docs/strict-mode.html 참고
  <RouterProvider router={router} />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
