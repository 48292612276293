import { ThemeOptions } from '@mui/material/styles';

// Utils
import { hexToRGBA } from '@src/utils/helper';

// Custom BreakPoints For Typescript
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }

  interface Palette {
    socialColor: {
      kakao: string;
      naver: string;
      google: string;
    };
  }
  interface PaletteOptions {
    socialColor: {
      kakao: string;
      naver: string;
      google: string;
    };
  }

  interface TypographyVariants {
    landingTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    landingTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    landingTitle: true;
  }
}

const FONT_FAMILY = [
  "'Pretendard Variable'",
  'Pretendard',
  '-apple-system',
  'BlinkMacSystemFont',
  'system-ui',
  'Roboto',
  "'Helvetica Neue'",
  "'Segoe UI'",
  "'Apple SD Gothic Neo'",
  "'Noto Sans KR'",
  "'Malgun Gothic'",
  "'Apple Color Emoji'",
  "'Segoe UI Emoji'",
  "'Segoe UI Symbol'",
  'sans-serif',
];

export const MUI_THEME: ThemeOptions = {
  palette: {
    primary: {
      light: '#f35d74',
      main: '#bb2649',
      dark: '#850022',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#edf7cf',
      main: '#bac49e',
      dark: '#8a946f',
      contrastText: '#ffffff',
    },
    error: {
      light: '#ff7f56',
      main: '#e94c2a',
      dark: '#af0c00',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#ffe24c',
      main: '#f2b004',
      dark: '#ba8100',
      contrastText: '#000000',
    },
    info: {
      light: '#6aecff',
      main: '#14baee',
      dark: '#008abb',
      contrastText: '#ffffff',
    },
    success: {
      light: '#85ff84',
      main: '#4ecd54',
      dark: '#009b24',
      contrastText: '#ffffff',
    },
    text: {
      primary: hexToRGBA('#000000', 0.87),
      secondary: hexToRGBA('#000000', 0.6),
    },
    socialColor: {
      naver: '#1EC800',
      kakao: '#FEE500',
      google: '#EEEEEE',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY.join(','),
    h1: {
      fontFamily: ["'LeferiPointSpecial'", ...FONT_FAMILY].join(','),
      fontSize: '9.6rem',
    },
    h2: {
      fontFamily: ["'LeferiPointSpecial'", ...FONT_FAMILY].join(','),
      fontSize: '6rem',
    },
    h3: {
      fontFamily: ["'LeferiPointBase'", ...FONT_FAMILY].join(','),
      fontSize: '4.8rem',
    },
    h4: {
      fontFamily: ["'LeferiPoint-White'", ...FONT_FAMILY].join(','),
      fontSize: '3.4rem',
    },
    h5: {
      fontFamily: ["'LeferiPoint-White'", ...FONT_FAMILY].join(','),
      fontSize: '2.4rem',
    },
    h6: {
      fontFamily: [...FONT_FAMILY].join(','),
      fontSize: '2rem',
    },
    overline: {
      fontFamily: 'Roboto',
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontFamily: 'Roboto',
    },
    landingTitle: {
      fontFamily: ["'LeferiPointSpecial'", ...FONT_FAMILY].join(','),
      fontSize: '60px',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      font-family: ${FONT_FAMILY.join(',')};
    `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          landingTitle: 'span',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
  },
};
