import { HTMLAttributes } from 'react';
import { List, type ListProps } from '@mui/material';

type TCommonContainerProps = ListProps;

function CommonList({ children, ...rest }: TCommonContainerProps) {
  return <List {...rest}>{children}</List>;
}

export default CommonList;
