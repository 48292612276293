import { useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';

import {
  Box,
  MenuList,
  MenuItem,
  Paper,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  Menu,
  IconButton,
} from '@mui/material';
import {
  AccountCircle,
  CreditCard as CreditCardIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { common } from '@mui/material/colors';

// Atoms
import { isShowProfileMenu as atomIsShowProfileMenu } from '@src/atoms/common/sideMenu';
import { authInfo as atomAuthInfo } from '@src/atoms/user';

// Utils
import { hexToRGBA, isMintor, pageMoveWithNavigator } from '@src/utils/helper';
import { goToCCCV } from '@src/utils/LinkRoute';

// i18n
import { useTranslation } from 'react-i18next';

// Lib
import appLocalStorage from '@src/lib/storage/localStorage';
import { AUTH_KEY } from '@src/lib/axios/apiClient';

// Route
import { cccvRoute, routerRegex } from '@src/route/routeConfig';

const storageAuthkey = appLocalStorage.get(AUTH_KEY);

function ProfileMenu() {
  const { t } = useTranslation();
  const { search: locationSearch, pathname: locationPathName } = useLocation();
  const navigator = useNavigate();

  const userAuthInfo = useAtomValue(atomAuthInfo);
  const resetAuthInfo = useResetAtom(atomAuthInfo);

  const [isShowProfileMenuAnchor, setIsShowProfileMenuAnchor] =
    useState<null | HTMLElement>(null);
  const open = Boolean(isShowProfileMenuAnchor);

  const handleClickAccountCircle = (event: React.MouseEvent<HTMLElement>) => {
    if (userAuthInfo.user !== null) {
      setIsShowProfileMenuAnchor(event.currentTarget);
    } else {
      // 로그인 페이지에서 클릭이벤트 block
      if (['/login'].includes(locationPathName)) return;
      // 로그인
      pageMoveWithNavigator({
        message: '',
        navigateInstance: navigator,
        params: {
          to: `${routerRegex.login.path}?login_return_url=${encodeURIComponent(
            locationPathName + locationSearch,
          )}`,
        },
      });
    }
  };

  const handleClose = () => {
    setIsShowProfileMenuAnchor(null);
  };

  const onClickLogout = () => {
    appLocalStorage.clean([AUTH_KEY]);
    resetAuthInfo();
    window.location.href = '/';
  };

  const onClickMyUsageHistory = () => {
    const destination = cccvRoute.usageHistory;

    goToCCCV(
      `${process.env.REACT_APP_CCCV_URL}/ext?access_token=${
        storageAuthkey.accessToken
      }&refresh_token=${
        storageAuthkey.refreshToken
      }&destination=${destination.replace(
        /#{pageId}/g,
        userAuthInfo?.user?.pageId,
      )}&success_url=none&service=1409`,
      'location',
    );
  };

  const onClickMyPaymentHistory = () => {
    const destination = cccvRoute.paymentHistory;

    goToCCCV(
      `${process.env.REACT_APP_CCCV_URL}/ext?access_token=${
        storageAuthkey.accessToken
      }&refresh_token=${
        storageAuthkey.refreshToken
      }&destination=${destination.replace(
        /#{pageId}/g,
        userAuthInfo?.user?.pageId,
      )}&success_url=none&service=1409`,
      'location',
    );
  };

  const onClickMintButton = () => {
    pageMoveWithNavigator({
      message: '',
      navigateInstance: navigator,
      params: {
        to: `${routerRegex.mint.path}`,
      },
    });
  };

  const renderMintButton = () => {
    if (isMintor(userAuthInfo?.user?.roles)) {
      return (
        <Button
          onClick={onClickMintButton}
          variant="contained"
          sx={{
            fontSize: '1.4rem',
            fontWeight: 500,
            mr: {
              xs: '4px',
              sm: 4,
            },
          }}
        >
          {t('콘텐츠 발행')}
        </Button>
      );
    }

    return null;
  };

  const renderProfileMenu = () => {
    if (userAuthInfo.user !== null) {
      return (
        <Menu
          anchorEl={isShowProfileMenuAnchor}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          MenuListProps={{
            sx: {
              py: 0,
              minWidth: '200px',
            },
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0.3,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <ListItem
            sx={{
              py: 2,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'body1',
                component: 'span',
                sx: (theme) => ({
                  fontSize: '1.6rem',
                  color: theme.palette.text.primary,
                }),
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                component: 'span',
                sx: (theme) => ({
                  fontSize: '1.4rem',
                  color: theme.palette.text.secondary,
                }),
              }}
              primary={userAuthInfo?.user?.user?.profile?.name}
              secondary={userAuthInfo?.user?.user?.profile?.email}
            />
          </ListItem>
          <Divider />
          <MenuItem
            sx={{
              py: 1,
            }}
            onClick={() => {
              navigator(`/user/${userAuthInfo?.user?.pageId}`);
            }}
          >
            <ListItemIcon
              sx={{
                mr: 2,
              }}
            >
              <AccountCircle
                sx={(theme) => ({
                  width: '2.4rem',
                  height: '2.4rem',
                  color: `${hexToRGBA('#000000', 0.54)}`,
                })}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: '1.4rem',
              }}
            >
              {t('My page')}
            </Typography>
          </MenuItem>
          {isMintor(userAuthInfo?.user?.roles) && (
            <>
              <MenuItem sx={{ py: 1 }} onClick={onClickMyPaymentHistory}>
                <ListItemIcon
                  sx={{
                    mr: 2,
                  }}
                >
                  <CreditCardIcon
                    sx={(theme) => ({
                      width: '2.4rem',
                      height: '2.4rem',
                      color: `${hexToRGBA('#000000', 0.54)}`,
                    })}
                  />
                </ListItemIcon>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    fontSize: '1.4rem',
                  }}
                >
                  {t('정산관리')}
                </Typography>
              </MenuItem>
              <MenuItem sx={{ py: 1 }} onClick={onClickMyUsageHistory}>
                <ListItemIcon
                  sx={{
                    mr: 2,
                  }}
                >
                  <SettingsIcon
                    sx={(theme) => ({
                      width: '2.4rem',
                      height: '2.4rem',
                      color: `${hexToRGBA('#000000', 0.54)}`,
                    })}
                  />
                </ListItemIcon>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    fontSize: '1.4rem',
                  }}
                >
                  {t('이용내역')}
                </Typography>
              </MenuItem>
            </>
          )}
          <Divider
            sx={{
              m: `0 !important`,
            }}
          />
          <MenuItem
            onClick={onClickLogout}
            sx={{
              fontFamily: 'LeferiPoint-White',
              fontSize: '1.3rem',
              py: '12px',
            }}
          >
            {t('LOG OUT')}
          </MenuItem>
        </Menu>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
      }}
    >
      {renderMintButton()}
      <Box sx={{ display: { md: 'flex' } }}>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="primary"
          onClick={handleClickAccountCircle}
          sx={{
            width: '3.8rem',
            height: '3.8rem',
            p: 0,
          }}
        >
          {/* 프로필 이미지 대체 최적화 필요 */}
          {userAuthInfo?.user?.profileImgUrl ? (
            <Box
              sx={{
                display: 'inline-flex',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <img
                src={userAuthInfo?.user?.profileImgUrl}
                alt="프로필 이미지"
              />
            </Box>
          ) : (
            <AccountCircle
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </IconButton>
      </Box>
      {/* Profile Menu */}
      {renderProfileMenu()}
      {/* !-- Profile Menu */}
    </Box>
  );
}

export default ProfileMenu;
