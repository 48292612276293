interface ISetParams {
  [key: string]: string | number;
}

const appSessionStorage = {
  get: function (key: string) {
    const getData = window.sessionStorage.getItem(key);

    return getData ? JSON.parse(getData) : null;
  },
  set: function (paramsObj: ISetParams) {
    Object.keys(paramsObj).forEach((key) => {
      window.sessionStorage.setItem(key, JSON.stringify(paramsObj[key]));
    });
  },
  clean: function (array: string[]) {
    const _array = array;
    _array.forEach((key) => {
      window.sessionStorage.removeItem(key);
    });
  },
};

export default appSessionStorage;
