import { useAtom, useSetAtom } from 'jotai';

// MUI
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Language as LanguageIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

// atoms
import {
  isFoldLanguageList as atomIsFoldLanguageList,
  isShowSideMenu as atomIsShowSideMenu,
} from '@src/atoms/common/sideMenu';
// i18n
import { i18n, WHITELIST_LANGUAGE_STRING } from '@src/lib/i18n/react-i18next';
import { useTranslation } from 'react-i18next';

export default function LanguageList() {
  // 언어선택 Open Flag
  const [isFoldLanguageList, setIsFoldLanguageList] = useAtom(
    atomIsFoldLanguageList,
  );
  // 사이드메뉴 Open Flag
  const setShowSideMenu = useSetAtom(atomIsShowSideMenu);

  const { t } = useTranslation();

  const onClickLanguage = (
    languageCode: keyof typeof WHITELIST_LANGUAGE_STRING,
  ) => {
    i18n.changeLanguage(languageCode);
    setShowSideMenu(false);
  };

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          onClick={() => setIsFoldLanguageList(!isFoldLanguageList)}
          sx={{
            px: 0,
          }}
        >
          <ListItemIcon>
            <LanguageIcon
              color="primary"
              sx={{
                width: '2rem',
                height: '2rem',
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                fontFamily: 'LeferiPoint-White',
                fontSize: '1.4rem',
                textTransform: 'uppercase',
                fontWeight: 300,
              },
            }}
            primary={t('Language')}
          />
          {isFoldLanguageList ? (
            <ExpandLessIcon
              sx={{
                width: '2rem',
                height: '2rem',
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                width: '2rem',
                height: '2rem',
              }}
            />
          )}
        </ListItemButton>
        <Collapse in={isFoldLanguageList} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              pl: 3,
            }}
          >
            {Object.entries(WHITELIST_LANGUAGE_STRING).map(
              ([code, text]: Array<
                string | keyof typeof WHITELIST_LANGUAGE_STRING
              >) => {
                return (
                  <ListItemButton
                    selected={i18n.language.includes(code)}
                    key={code}
                    sx={{ pl: 4 }}
                    onClick={() =>
                      onClickLanguage(
                        code as keyof typeof WHITELIST_LANGUAGE_STRING,
                      )
                    }
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: 'Roboto',
                          fontSize: '1.4rem',
                        },
                      }}
                      primary={t(text)}
                    />
                  </ListItemButton>
                );
              },
            )}
          </List>
        </Collapse>
      </List>
    </>
  );
}
