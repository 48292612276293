import { checkTokenStatus } from '@src/common/api';

type goToCCCVRoutingType = 'location' | 'open';

export const goToCCCV = async (
  url: string,
  routingType: goToCCCVRoutingType = 'location',
) => {
  try {
    const result = await checkTokenStatus();
    if (result.status !== 401) {
      if (routingType === 'location') {
        window.location.href = url;
      } else {
        window.open(url, '_blank'); // < backkey disable
        // window.open(url, '_self'); // < backkey enable
      }
    }
  } catch (err) {
    window.location.href = `${process.env.REACT_APP_CCCV_URL}/nft`;
  }
};
