import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// Router
import Root from '@src/Root';

// Auth Router
import KakaoAuth from '@src/pages/Auth/KakaoAuth';
import NaverAuth from '@src/pages/Auth/NaverAuth';
import GoogleAuth from '@src/pages/Auth/GoogleAuth';

// loader
import { loader as ProductDetailLoader } from '@src/route/loader/ProductDetail/loader';
import { buyable } from './loader/buyable';

const MainPage = lazy(() => import('@src/pages/Main'));
const MyPagePage = lazy(() => import('@src/pages/MyPage'));
const SearchPage = lazy(() => import('@src/pages/Search'));
const LoginPage = lazy(() => import('@src/pages/Login'));
const MintPage = lazy(() => import('@src/pages/Mint'));
const NotFoundPage = lazy(() => import('@src/pages/NotFound'));
const CollectionPage = lazy(() => import('@src/pages/Collection'));
const HowtoPage = lazy(() => import('@src/pages/Howto'));
const AboutPage = lazy(() => import('@src/pages/About'));

/**
 * 상세페이지
 */
const ProductDetailPage = lazy(() => import('@src/pages/ProductDetail'));
const ProductDetailTabMenu1 = lazy(
  () => import('@src/components/ProductDetail/TabMenu1'),
);
const ProductDetailTabMenu2 = lazy(
  () => import('@src/components/ProductDetail/TabMenu2'),
);

/**
 * 서비스 이용약관
 */
const ServiceTermsPage = lazy(() => import('@src/pages/ServiceTerms'));

/**
 * 개인정보처리방침
 */
const PrivacyPage = lazy(() => import('@src/pages/Privacy'));

/**
 * CCCV 라우터
 */
export const cccvRoute = {
  mycccv: '/set',
  mint: '/nft/mint',
  pay: '/nft/#{id}/pay', // 일반 구매
  bid: '/nft/#{id}/bid', // 입찰
  offer: '/nft/#{id}/offer', // 제안
  download: '/nft/user/#{pageId}/his/download',
  usageHistory: '/nft/user/#{pageId}/his',
  paymentHistory: '/nft/user/#{pageId}/settle/list',
  myBadge: '/set/badges',
  editProfile: '/nft/user/#{pageId}',
  sellNFT: '/nft/#{tokenId}/sell',
  qr: '/qr',
};

/**
 * 1409 App 라우터
 */
export const routerRegex = {
  main: {
    path: '/',
  },
  mypage: {
    path: '/user/:pageId',
  },
  search: {
    path: '/search',
  },
  login: {
    path: '/login',
  },
  auth: {
    kakao: {
      path: '/_o/kakao',
    },
    google: {
      path: '/_o/google',
    },
    naver: {
      path: '/_o/naver',
    },
  },
  collection: {
    path: '/collection',
  },
  howto: {
    path: '/howto',
  },
  about: {
    path: '/about',
  },
  detail: {
    path: '/nft/:wId',
    info: {
      path: 'info',
    },
  },
  mint: {
    path: '/mint',
  },
  buyable: {
    path: '/nft/buyable/:gId',
  },
  serviceTerms: {
    path: '/terms',
  },
  privacy: {
    path: '/privacy',
  },
  '404': {
    path: '*',
  },
};

// 로더 재정의 및 분리 필요
/**
 * React Router Dom
 *
 * @remarks 로더 재정의 및 분리필요
 */
export const router = createBrowserRouter([
  {
    path: routerRegex.main.path,
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: routerRegex.main.path,
        element: <MainPage />,
      },
      {
        path: routerRegex.mypage.path,
        element: <MyPagePage />,
      },
      {
        path: routerRegex.search.path,
        element: <SearchPage />,
      },
      {
        path: routerRegex.login.path,
        element: <LoginPage />,
      },
      {
        path: routerRegex.auth.kakao.path,
        element: <KakaoAuth />,
      },
      {
        path: routerRegex.auth.google.path,
        element: <GoogleAuth />,
      },
      {
        path: routerRegex.auth.naver.path,
        element: <NaverAuth />,
      },
      {
        path: routerRegex.collection.path,
        element: <CollectionPage />,
      },
      {
        path: routerRegex.howto.path,
        element: <HowtoPage />,
      },
      {
        path: routerRegex.about.path,
        element: <AboutPage />,
      },
      {
        path: routerRegex.detail.path,
        element: <ProductDetailPage />,
        loader: ProductDetailLoader,
        children: [
          {
            path: '',
            element: <ProductDetailTabMenu1 />,
          },
          {
            path: routerRegex.detail.info.path,
            element: <ProductDetailTabMenu2 />,
          },
        ],
      },
      {
        path: routerRegex.buyable.path,
        loader: buyable,
      },
      {
        path: routerRegex.mint.path,
        element: <MintPage />,
      },

      {
        path: routerRegex.serviceTerms.path,
        element: <ServiceTermsPage />,
      },
      {
        path: routerRegex.privacy.path,
        element: <PrivacyPage />,
      },
      {
        path: routerRegex[404].path,
        element: <NotFoundPage />,
      },
    ],
  },
]);
