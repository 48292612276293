import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

// Component
import CommonList from '@src/components/atoms/List';
import CommonListItem from '@src/components/atoms/ListItem';
import CommonLink from '@src/components/atoms/Link';

// Route
import { routerRegex } from '@src/route/routeConfig';

function CommonNavItemList() {
  const NAV_ITEM = [
    {
      name: 'collection',
      path: routerRegex.collection.path,
    },
    {
      name: 'how to download',
      path: routerRegex.howto.path,
    },
    {
      name: 'about',
      path: routerRegex.about.path,
    },
  ];

  return (
    <CommonList
      sx={{
        py: 2,
        px: 1,
      }}
    >
      {NAV_ITEM.map((item) => (
        <CommonListItem
          key={item.name}
          sx={{
            px: 0,
          }}
        >
          <CommonLink<RouterLinkProps, typeof RouterLink>
            sx={(theme) => ({
              display: 'inline-flex',
              width: '100%',
              fontFamily: 'LeferiPoint-White',
              fontWeight: 300,
              fontSize: '1.4rem',
              textTransform: 'uppercase',
              letterSpacing: '0.4',
              color: theme.palette.text.primary,
            })}
            component={RouterLink}
            to={item.path}
          >
            {item.name}
          </CommonLink>
        </CommonListItem>
      ))}
    </CommonList>
  );
}

export default CommonNavItemList;
