import { axiosInstance as request } from '@src/lib/axios/apiClient';

const TAGS = 'tags=%231409';

export const cccvLogin = (type, auth) => {
  return request.post(`/auth/sign/${type}`, auth);
};

export const getKakaoAuthToken = (code) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', process.env.REACT_APP_KAKAO_REST_API_KEY);
  params.append('redirect_uri', process.env.REACT_APP_KAKAO_REDIRECT_URL);
  params.append('code', code);

  return request.post(`https://kauth.kakao.com/oauth/token`, params, {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded;',
    },
  });
};

export const getNaverAuthToken = (code) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', process.env.REACT_APP_NAVER_CLIENT_ID);
  // params.append('client_secret', '');
  params.append('state', 'RANDOM_STATE');
  params.append('code', code);
  params.append('auth_type', 'reauthenticate');

  return request.post(`https://nid.naver.com/oauth2.0/token`, params);
};

export const getKeyplaySeller = () => {
  const params = new URLSearchParams();
  params.append('limit', '9');
  return request.get(
    `${
      process.env.REACT_APP_CCCV_API_URL
    }/pages/tag-p/CCCV_KEYPLAY?${decodeURIComponent(params.toString())}`,
  );
};

export const getWhatsNew = (params, queryString = '') => {
  // 관리자단에서 노출한 NFT 콘텐츠 중 판매 등록된 콘텐츠,
  // 판매 등록이 안된 콘텐츠 최신순 리스트 4개씩

  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/product-groups${queryString}`,
    {
      params: params,
    },
  );
};

export const getNft = (id) => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/nft/${id}`);
};

export const getNftToken = (id) => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/nft/token/${id}`);
};

export const getUser = (id) => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/user/${id}`);
};

export const getPage = (id) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/pages/getPage?pageId=${id}`,
  );
};

export const getMyPageInfo = () => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/pages/myPage`);
};

export const getUserCreatedNfts = (
  id,
  limit,
  nextCursor,
  params,
  options = null,
) => {
  if (nextCursor)
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/nft/v2/user/${id}/created${nextCursor}`,
      {
        params: params,
        ...options,
      },
    );

  // let url = `${
  //   process.env.REACT_APP_CCCV_API_URL
  // }/nft/user/${id}/created?tags=@CCCV_KEYPLAY&limit=${limit ?? 9}`;

  // 임시
  const url = `${
    process.env.REACT_APP_CCCV_API_URL
  }/nft/v2/user/${id}/created?${TAGS}&limit=${limit ?? 9}`;

  return request.get(url, {
    params: params,
    ...options,
  });
};

export const getUserLikeNfts = (
  id,
  limit,
  nextCursor,
  params,
  options = null,
) => {
  if (nextCursor)
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/nft/v2/user/${id}/${TAGS}&like${nextCursor}`,
      {
        params: params,
        ...options,
      },
    );

  // 임시
  const url = `${
    process.env.REACT_APP_CCCV_API_URL
  }/nft/v2/user/${id}/like?${TAGS}&limit=${limit ?? 9}`;

  return request.get(url, {
    params: params,
    ...options,
  });
};

/**
 *
 * @param {*} id - URL에 기입된 유저 ID
 * @param {*} limit - 호출시, 로딩할 데이터 갯수
 * @param {*} isOnSale - 현재 판매중인 상품에 대한 필터링 플래그
 * @param {*} nextCursor - 다음 커서값
 * @param {*} params - 이외 추가적인 파라미터
 * @param {*} options - axios options
 * @returns
 */
export const getUserOwnedNfts = (
  id,
  limit,
  isOnSale = undefined,
  nextCursor = undefined,
  params,
  options = null,
) => {
  if (nextCursor)
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/nft/v2/user/${id}/${TAGS}&owned${nextCursor}`,
      {
        params: params,
        ...options,
      },
    );

  // let url = `${
  //   process.env.REACT_APP_CCCV_API_URL
  // }/nft/v2/user/${id}/owned?tags=%231409&limit=${limit ?? 9}`;
  let url = `${
    process.env.REACT_APP_CCCV_API_URL
  }/nft/v2/user/${id}/owned?${TAGS}&&limit=${limit ?? 9}`;

  if (isOnSale !== undefined) {
    url += `&isOnSale=${isOnSale}`;
  }

  return request.get(url, {
    params: params,
    ...options,
  });
};

export const getCountUserCreatedNfts = (id) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/user/${id}/count/created`,
  );
};

export const getCountUserOwnedNfts = (id) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/user/${id}/count/owned`,
  );
};

export const searchNfts = (keyword, limit, cursor = null, params) => {
  if (cursor) {
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/nft/v2/search${cursor}`,
      {
        params: params,
      },
    );
  }

  let query = '';
  if (keyword) query = `&query=${keyword}`;
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/v2/search?limit=${
      limit ?? 9
    }&tags=%231409${query}`,
    {
      params: params,
    },
  );
};

export const searchUsers = (keyword, limit, cursor = null) => {
  if (cursor) {
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/pages/tag-p/CCCV_KEYPLAY${cursor}`,
    );
  }

  let query = '';
  if (keyword) query = `&query=${keyword}`;
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/pages/tag-p/CCCV_KEYPLAY?limit=${
      limit ?? 9
    }${query}`,
  );
};

export const updateNftMainTag = (id, isAdd) => {
  return request.patch(`${process.env.REACT_APP_CCCV_API_URL}/nft/${id}/tags`, {
    isAdd,
    tags: ['@CCCV_KEYPLAY_MAIN'],
  });
};

export const deleteNft = (id) => {
  return request.delete(`${process.env.REACT_APP_CCCV_API_URL}/nft/${id}`);
};

export const deleteSaleWithTokenId = (id) => {
  return request.delete(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/token/${id}/sale`,
  );
};

export const deleteSaleWithGroupid = (id) => {
  return request.delete(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/my/${id}/sale`,
  );
};

export const checkPresentSendable = (email) => {
  return request.post(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-gift/sendable`,
    {
      email,
    },
  );
};

export const checkPresentExists = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-gift/exists/${tokenId}`,
  );
};

export const present = (tokenId, data) => {
  return request.post(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/token/${tokenId}/gift`,
    data,
  );
};

export const isAdmin = (userId) => {
  return request.get(`/admins/isAdmin?user_id=${userId}`);
};

/**
 *
 * 전달 후 추가된 API
 *
 */
export const getProductsSoldByCategory = (params) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/product-groups/category/@CCCV_KEYPLAY_MAIN`,
    {
      params: params,
    },
  );
};

export const getColletionByCategory = (nextCursor, params) => {
  let cursor = '';

  if (nextCursor) {
    cursor = nextCursor;
    /// nextCursor에 limit, tags가 중복으로 들어감
    return request.get(
      `${process.env.REACT_APP_CCCV_API_URL}/product-groups/category/all${cursor}`,
    );
  }

  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/product-groups/category/all`,
    {
      params: params,
    },
  );
};

export const getNftEventByToken = (requireParams, params, queryString = '') => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-event/token/${requireParams.id}/history${queryString}`,
    {
      params: params,
    },
  );
};

export const isExistBadge = (params) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/pageBadges/isExists`,
    {
      params: params,
    },
  );
};

export const getMyBadgeList = () => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/pageBadges/my`);
};

export const getBadgeListByPageId = (params) => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/pageBadges`, {
    params: params,
  });
};

export const getNowPlaying = (title, params) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/curation/title/${title}/items`,
    {
      params: params,
    },
  );
};

export const getEditionInfoPerPageByGroupid = (
  groupId,
  params,
  queryString = '',
) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/${groupId}/editionInfo-p${queryString}`,
    {
      params: params,
    },
  );
};

export const getEditionInfoByGroupid = (groupId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/${groupId}/editionInfo`,
  );
};

export const getNftFundingInfoByGroupid = (groupId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-funding/nft/${groupId}`,
  );
};

export const getMyVerifications = () => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/verifications/myVerifications`,
  );
};

export const verificationPhone = (impUID) => {
  return request.post(
    `${process.env.REACT_APP_CCCV_API_URL}/verifications/verificationPhone`,
    {
      impUID,
    },
  );
};

export const getDownloadReceipt = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/download-receipt/my/token/${tokenId}`,
  );
};

export const getBuyableEditionURL = async (groupId) => {
  let returnURL = '/err';

  try {
    const { data } = await getNft(groupId);
    if (data) {
      const buyableItemArray = data.nftTokens.filter(
        (item) =>
          data.creator_id === item.owner_id &&
          item.isActive &&
          item.paymentProductType !== null &&
          (item.paymentProductType === 'nftSale' ||
            item.paymentProductType === 'nftAuction'),
      );
      const notSaleItemArray = data.nftTokens.filter(
        (item) => data.creator_id === item.owner_id && item.isActive,
      );
      const activeItemArray = data.nftTokens.filter((item) => item.isActive);

      const randomBuyAbleItem = buyableItemArray[0];
      const randomNotSaleItem = notSaleItemArray[0];
      const randomActiveItem = activeItemArray[0];

      if (randomBuyAbleItem) {
        returnURL = `/nft/${randomBuyAbleItem._id}`;
      } else if (randomNotSaleItem) {
        returnURL = `/nft/${randomNotSaleItem._id}`;
      } else if (randomActiveItem) {
        returnURL = `/nft/${randomActiveItem._id}`;
      } else {
        returnURL = '/err';
      }
    } else {
      returnURL = '/err';
    }
  } catch (e) {
    returnURL = '/err';
  }

  return returnURL;
};

export const deleteSaleNftWithTokenId = (tokenId, type = 'sale') => {
  return request.delete(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/token/${tokenId}/${type}`,
  );
};

export const deleteSaleNftWithGroupid = (groudId, type = 'sale') => {
  return request.delete(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/my/${groudId}/${type}`,
  );
};

export const getTokensByPageId = (groupId, pageId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/${groupId}/Tokens/${pageId}`,
  );
};

export const getIsSecretRewardNft = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/token/${tokenId}/ismeta`,
  );
};

export const getSecretRewardMetaData = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft/token/${tokenId}/meta`,
  );
};

export const checkTokenStatus = () => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/users/whoami`);
};

export const getIsQRNFTExists = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-qr/nft-token/${tokenId}/exists`,
  );
};

export const getNftqrOtp = (tokenId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/nft-qr/my/nft-token/${tokenId}/otp`,
  );
};

export const getIsBuyableNftByPaymentProductId = (paymentProductId) => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/payment-product/${paymentProductId}/purchasable-tmp`,
  );
};
