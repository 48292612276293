// MUI
import { useEffect, useState } from 'react';
import { atomWithStorage } from 'jotai/utils';
import { Box, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { red } from '@mui/material/colors';

// helper
import { hexToRGBA } from '@src/utils/helper';

// Component
import CommonInputBase from '@src/components/atoms/Input';
import { useSearchParams } from 'react-router-dom';

function CommonSearchInput() {
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  // const initValue = 123;

  const enterkey = (e: any) => {
    if (e.key === 'Enter') {
      saveSearchWord();
    }
  };

  const saveSearchWord = () => {
    if (searchInput) {
      setSearchParams({ search: searchInput });
    } else {
      setSearchParams({});
    }
    window.location.reload();
  };

  useEffect(() => {
    const querySearch = searchParams.get('search');
    if (querySearch) setSearchInput(querySearch);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        display: 'inline-flex',
        width: '100%',
        borderRadius: 1,
        backgroundColor: `${hexToRGBA(theme.palette.primary.main, 0.12)}`,
        alignItems: 'center',
        px: 1,
      })}
    >
      <IconButton
        type="button"
        sx={{ p: '8px' }}
        aria-label="search"
        onClick={saveSearchWord}
      >
        <SearchIcon
          color="primary"
          sx={{
            width: '20px',
            height: '20px',
          }}
        />
      </IconButton>
      <CommonInputBase
        id="searchInput"
        sx={(theme) => ({
          flex: 1,
          color: `${hexToRGBA(theme.palette.primary.main, 0.42)}`,
          fontSize: '1.6rem',
        })}
        value={searchInput}
        placeholder="Search..."
        onKeyUp={enterkey}
        onChange={(event) => {
          setSearchInput(event.target.value);
        }}
      />
    </Box>
  );
}

export default CommonSearchInput;
