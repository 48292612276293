import { type LoaderFunctionArgs } from 'react-router-dom';

// API
import { getNftDetail, getTransferInfo } from '@src/api/productDetail';

// Atom
import { IWork } from '@src/atoms/detail';

const loader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<IWork> => {
  let nftInfo: IWork;

  const data = getNftDetail(`${params.wId}`)
    .then((res) => {
      // nftInfo = res.data;
      nftInfo = res.data;
      // console.log(nftInfo);
      if (nftInfo.paymentProduct) {
        nftInfo.displayPrice = nftInfo.paymentProduct.price;
      }

      try {
        if (!nftInfo.displayPrice) {
          getTransferInfo(`${nftInfo?._id}`)
            .then((res) => {
              nftInfo.displayPrice = res.data.firstSoldPrice;
              // return nftInfo;
            })
            .catch((err) => {
              console.error(err);
              // return nftInfo;
            });
        }
      } catch (e) {
        console.log(e);
      }
      return nftInfo;
    })
    .catch((err) => {
      console.error(err);
      return nftInfo;
    });

  return data;
};

export { loader };
