import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

// utils
import appLocalStorage from '@src/lib/storage/localStorage';

// atoms
import {
  asyncSetAuthInfoUser as actionAuthInfoUser,
  asyncSetAuthInfoIsVerification as actionSetAuthInfoIsVerification,
} from '@src/atoms/user';

// API
import { AUTH_KEY } from '@src/lib/axios/apiClient';

// Utils
import { goToAppPageWithHref } from '@src/utils/helper';

// Route
import { routerRegex } from '@src/route/routeConfig';

export default function GoogleAuthRouter() {
  const setAuthInfoUser = useSetAtom(actionAuthInfoUser);
  const setAuthInfoIsVerification = useSetAtom(actionSetAuthInfoIsVerification);

  useEffect(() => {
    (async () => {
      const { search } = window.location;
      const params = new URLSearchParams(search);

      const accessToken = params.get('accessToken');
      const refreshToken = params.get('refreshToken');
      const loginReturnUri = params.get('login_return_url');

      // login return uri
      const pageReturnURI = loginReturnUri;

      try {
        if (accessToken && refreshToken) {
          appLocalStorage.set({
            [AUTH_KEY]: {
              accessToken,
              refreshToken,
            },
          });

          setAuthInfoUser();
          setAuthInfoIsVerification();
        }

        window.location.href = pageReturnURI
          ? decodeURIComponent(pageReturnURI)
          : routerRegex.main.path;
      } catch (err) {
        goToAppPageWithHref('로그인에 실패하였습니다.', routerRegex.main.path);
      }
    })();
  }, []);

  return <div>...Loading Animation</div>;
}
