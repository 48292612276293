import { styled } from '@mui/system';

export const CommonFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export const FooterInnerStyle = {
  display: 'inline-flex',
  width: '100%',
};

export const FooterLinkList = {
  display: 'inline-flex',
  flexWrap: 'wrap',
  py: 0,
};

export const FooterLinkListItem = {
  display: 'inline-flex',
  width: 'auto',
  py: 0,
  px: 0,
  wordBreak: 'keep-all',
};

export const FooterLogoImage = styled('img')({
  height: '34px',
});
