const appLocalStorage = {
  get: function (key: string) {
    const getData = localStorage.getItem(key);

    return getData ? JSON.parse(getData) : null;
  },
  set: function (paramsObj: any) {
    Object.keys(paramsObj).forEach((key) => {
      localStorage.setItem(key, JSON.stringify(paramsObj[key]));
    });
  },
  clean: function (array: string[]) {
    const _array = array;
    _array.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};

export default appLocalStorage;
