import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetAtom } from 'jotai';

// atoms
import { isShowSideMenu as atomIsShowSideMenu } from '@src/atoms/common/sideMenu';

function ScrollToTop() {
  const location = useLocation();
  const setIsShowSideMenu = useSetAtom(atomIsShowSideMenu);

  useLayoutEffect(() => {
    // location 변경 시, 사이드메뉴 닫기 / 리팩토링 필요
    setIsShowSideMenu(false);
  }, [location]);

  return null;
}

export default ScrollToTop;
