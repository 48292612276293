import i18n from 'i18next';

// i18n Plugin
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// i18n JSON files
import * as translationFile from '@src/i18n';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

enum WHITELIST_LANGUAGE_STRING {
  ko = 'Korean',
  en = 'English',
  ja = 'Japanese',
  zh = 'Chinese',
}

const resources = {
  en: {
    translation: translationFile.en,
  },
  ko: {
    translation: translationFile.ko,
  },
  zh: {
    translation: translationFile.zh,
  },
  ja: {
    translation: translationFile.ja,
  },
};

const initialize = () => {
  // changeLanguage - https://www.i18next.com/overview/api#changelanguage
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export { WHITELIST_LANGUAGE_STRING, i18n, initialize };
