import { Suspense, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// MUI
import { Container, Box } from '@mui/material';

// Pages
import SuspensePage from '@src/pages/SuspensePage';

// Layout
import CommonHeader from '@src/layout/CommonHeader';
import CommonFooter from '@src/layout/CommonFooter';
import CommonSideMenu from '@src/layout/CommonSideMenu';

// styles
import * as S from '@src/layout/CommonMain/styles';

const HeaderHidingRouter = ['/_o/kakao', '/_o/google', '/_o/naver'];
const FooterHidingRouter = ['/login', '/_o/kakao', '/_o/google', '/_o/naver'];

export default function CommonMain() {
  const location = useLocation();
  const MainContainer = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {HeaderHidingRouter.indexOf(location.pathname) === -1 && <CommonHeader />}
      <Box
        className="CommonMainWrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: 8,
          flex: '1 1 0',
        }}
      >
        <S.CommonMain className="CommonMain">
          <S.CommonMainInner className="CommonMainInner CommonMain__Inner">
            <CommonSideMenu positionContainer={MainContainer.current} />
            <Container
              component="main"
              ref={MainContainer}
              sx={{
                px: '0!important',
              }}
              maxWidth={false}
            >
              <Suspense fallback={<SuspensePage />}>
                <Outlet />
              </Suspense>
            </Container>
          </S.CommonMainInner>
        </S.CommonMain>
        {FooterHidingRouter.indexOf(location.pathname) === -1 && (
          <CommonFooter />
        )}
      </Box>
    </>
  );
}
