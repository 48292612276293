import { NavigateOptions, To } from 'react-router-dom';

import * as api from '@src/common/api';

// route config
import { routerRegex } from '@src/route/routeConfig';

// library
import appSessionStorage from '@src/lib/storage/sessionStorage';

// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html
type Tunit = {
  'ko-KR': 'KRW';
};
export type ValueOf<T> = T[keyof T];
export interface IConvertImgScrToOndemandImgOptions {
  width: number;
  height: number;
}

const moneyUnit: Tunit = {
  'ko-KR': 'KRW',
};

export const getKeyNameOfEnum = (object: any, value: string) =>
  Object.entries(object).find(([key, val]) => val === value)?.[0];

export const judgeHistoryType = (target: string): string => {
  let type = '';

  if (target === 'mint') {
    type = 'Created by';
  } else if (target === 'list') {
    type = 'List by';
  } else if (target === 'gift') {
    type = 'Gift';
  } else if (target === 'draw') {
    type = 'Draw';
  } else if (target === 'swap') {
    type = 'Swap';
  } else if (target === 'bid') {
    type = 'Bid';
  } else if (target === 'sale') {
    type = 'Sale';
  } else {
    type = '';
  }

  return type;
};

export const generateNumberToLocaleString = (
  number = 0,
  countryCode: keyof Tunit = 'ko-KR',
): string => {
  const _number: number = number;

  return _number.toLocaleString(countryCode);
};

export const getMoneyUnitByCountry = (
  countryCode: keyof Tunit,
): ValueOf<Tunit> => moneyUnit[countryCode];

// groupid format == "{groupId}_{edition}"
export const convertTokenIdToGroupId = (groupId: string) => {
  const nftIdArray = groupId.split('_');

  return nftIdArray[0];
};

export const isEditionTags = (tags: Array<string>) =>
  !tags.includes('!edition');

export const convertImgScrToOndemandImg = (
  url: string,
  options: IConvertImgScrToOndemandImgOptions = {
    width: 291,
    height: 411,
  },
) => {
  const isLive = process.env.REACT_APP_URL === 'https://keyplay.cccv.to';

  const splitSeparator = isLive ? '/files/' : '/d_files/';
  const imageURL = url.split(splitSeparator);
  const imageServerURL = isLive
    ? `https://img.cccv.to/inside/${options.width}x${options.height}/files/`
    : `https://img.cccv.to/inside/${options.width}x${options.height}/d_files/`;

  const convertedURL = `${imageServerURL}${imageURL[1]}`;

  return convertedURL;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const shuffleArray = (array: Array<any>) => {
  const _array = array;
  for (let i = 0; i < _array.length; i += 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [_array[i], _array[j]] = [_array[j], _array[i]];
  }
  return _array;
};

export const convertObjectToQueryString = (_search: any): string => {
  if (typeof _search === 'undefined') return '';

  return `?${Object.entries(_search)
    .reduce((_prev: Array<any>, _curr: any) => {
      _prev.push(_curr.join('='));
      return _prev;
    }, [])
    .join('&')}`;
};
export const getQueryStringObject = (_search: string): any => {
  const a = _search.substr(1).split('&');
  if (a.length <= 0) return [];
  const b: any = {};
  for (let i = 0; i < a.length; i += 1) {
    const p = a[i].split('=', 2);
    if (p.length === 1) b[p[0] as unknown as number] = '';
    else {
      b[p[0] as unknown as number] = decodeURIComponent(
        p[1].replace(/\+/g, ' '),
      );
    }
  }
  return b;
};

export const replaceState = (_url: string, _state?: any) => {
  const state = _state;
  window.history.replaceState(state, '', _url);
};

export const aergoURL = (_state: string) => {
  let argoURL = process.env.REACT_APP_AERGO_SCAN_URL;

  if (_state === 'Contract') {
    argoURL += '/account';
  } else if (_state === 'Txid') {
    argoURL += '/transaction';
  } else {
    argoURL = process.env.REACT_APP_AERGO_SCAN_URL as string;
  }

  return argoURL;
};

interface IPageMoveWithNavigator {
  message: string;
  navigateInstance: any;
  params: {
    to: To;
    options?: NavigateOptions;
  };
}

export const pageMoveWithNavigator = ({
  message,
  navigateInstance,
  params,
}: IPageMoveWithNavigator) => {
  // 이동하려는 URL이 현재 위치와 동일하면 navigator block
  if (window.location.pathname + window.location.search === params.to) return;
  if (routerRegex.login.path === params.to)
    // 로그인 페이지로 이동 시, login loading 제거
    appSessionStorage.clean(['login_loading']);

  if (message) {
    alert(message);
  }
  navigateInstance(params.to, params?.options);
};

export const goToAppPageWithHref = (_message: string, _url: any) => {
  // 로그인 페이지로 이동 시, login loading 제거
  if (routerRegex.login.path === _url)
    appSessionStorage.clean(['login_loading']);

  if (_message) {
    alert(_message);
  }

  window.location.href = _url;
};

interface IGetNftSpecialStatusByNftReturn {
  isGifted: boolean;
  isIncludePack: boolean;
  isSynced: boolean;
  isSwaped: boolean;
}

export const getNftStatusByNft = (
  _nft: any,
): IGetNftSpecialStatusByNftReturn => {
  const returnValue = {
    isGifted: false,
    isIncludePack: false,
    isSynced: false,
    isSwaped: false,
  };

  returnValue.isGifted = _nft.status === 'GIFT';
  returnValue.isIncludePack = _nft.status === 'LUCK';
  returnValue.isSynced = _nft.isSynced;
  returnValue.isSwaped = _nft.isSwaped;

  return {
    isGifted: returnValue.isGifted,
    isIncludePack: returnValue.isIncludePack,
    isSynced: returnValue.isSynced,
    isSwaped: returnValue.isSwaped,
  };
};

interface IAbleCheckHelperParams {
  _nft: any;
  _user: any;
  _isSecretReward: boolean;
  _isVerificated: boolean;
}

interface IDefaultCheckCallback {
  blockingCallbackByLogin?: () => void;
  blockingCallbackByVerificate?: () => void;
  blockingCallbackBySecretReward?: () => void;
}

interface IOfferableBlockingCallback extends IDefaultCheckCallback {
  blockingCallbackByGift?: () => void;
  blockingCallbackByRandomPack?: () => void;
  blockingCallbackByBlockchainSync?: () => void;
  blockingCallbackByErcSwap?: () => void;
  blockingCallbackByAdultCheck?: () => void;
}

interface IBuyableNftBlockingCallback extends IDefaultCheckCallback {
  blockingCallbackByDuplicatePurchase?: () => void;
}

export const isOfferableNft = (
  _nft: IAbleCheckHelperParams['_nft'],
  _user: IAbleCheckHelperParams['_user'],
  _isSecretReward: IAbleCheckHelperParams['_isSecretReward'] = false,
  _isVerificated: IAbleCheckHelperParams['_isVerificated'] = false,
  _callback: IOfferableBlockingCallback,
): boolean => {
  const { isGifted, isIncludePack, isSynced, isSwaped } =
    getNftStatusByNft(_nft);

  // nft정보가 없을 경우 예외처리
  if (!_nft) {
    return false;
  }

  // NOTE:1. 로그인 여부
  if (!_user) {
    _callback.blockingCallbackByLogin?.();
    return false;
  }

  // NOTE:2. 본인인증 여부
  if (!_isVerificated) {
    _callback.blockingCallbackByVerificate?.();
    return false;
  }

  // NOTE:3. 선물된 상품의 경우
  if (isGifted) {
    _callback.blockingCallbackByGift?.();
    return false;
  }

  // NOTE:4. 랜덤펙이 포함된 상품의 경우
  if (isIncludePack) {
    _callback.blockingCallbackByRandomPack?.();
    return false;
  }

  // NOTE:5. 블록체인 처리중인 상품의 경우
  if (!isSynced) {
    _callback.blockingCallbackByBlockchainSync?.();
    return false;
  }

  // NOTE:6. ERC Swap 된 경우
  if (isSwaped) {
    _callback.blockingCallbackByErcSwap?.();
    return false;
  }

  // NOTE:7. 시크릿 리워드일 경우
  if (_isSecretReward) {
    _callback.blockingCallbackBySecretReward?.();
    return false;
  }

  // NOTE:8. 성인인증
  // if (_isVerificated) {
  //   if (_callback?.blockingCallbackByAdultCheck) _callback.blockingCallbackByAdultCheck();
  //   return false;
  // }

  return true;
};

export const isBuyableNft = async (
  _nft: IAbleCheckHelperParams['_nft'],
  _user: IAbleCheckHelperParams['_user'],
  _isSecretReward: IAbleCheckHelperParams['_isSecretReward'] = false,
  _isVerificated: IAbleCheckHelperParams['_isVerificated'] = false,
  _callback: IBuyableNftBlockingCallback,
) => {
  // nft정보가 없을 경우 예외처리
  if (!_nft) {
    return false;
  }

  // NOTE:1. 로그인 여부
  if (!_user) {
    if (_callback?.blockingCallbackByLogin) _callback.blockingCallbackByLogin();
    return false;
  }

  /**
   * 2022-08-30 - 구매 시, 필수
   */
  // NOTE:2. 본인인증 여부
  if (!_isVerificated) {
    _callback.blockingCallbackByVerificate?.();
    return false;
  }

  // NOTE:3. 다른 사람 구매중인지 판단
  let isBuyableByDuplicatePurchaseStatus = false;
  // 예외처리 확인필요
  const { data }: any = await api.getIsBuyableNftByPaymentProductId(
    _nft.paymentProductId,
  );
  isBuyableByDuplicatePurchaseStatus = data.purchasable;

  if (!isBuyableByDuplicatePurchaseStatus) {
    _callback.blockingCallbackByDuplicatePurchase?.();
    return false;
  }

  // NOTE:4. 시크릿 리워드일 경우
  if (_isSecretReward) {
    _callback.blockingCallbackBySecretReward?.();
    return false;
  }

  // NOTE:5. 성인인증
  return true;
};

// Load Script Tag
export interface ILoadScriptTag<T> {
  type: keyof React.ReactHTML;
  elementAttribute: T;
  callbackAfterLoad?: () => any;
}
export const loadScriptTag = <attrType extends {}>({
  type = 'script',
  elementAttribute,
  callbackAfterLoad,
}: ILoadScriptTag<attrType>) => {
  return new Promise((resolve, reject) => {
    if (!elementAttribute) reject(new Error(`elementAttribute Error`));
    const _scriptTag = document.createElement(type) as HTMLScriptElement;
    Object.entries(elementAttribute).forEach((item: Array<unknown>) => {
      const _key = item[0] as keyof HTMLScriptElement;
      const _value = item[1];

      _scriptTag.setAttribute(_key, _value as string);
    });

    _scriptTag.onload = () => {
      callbackAfterLoad?.();
      resolve({
        status: 'load',
      });
    };
    _scriptTag.onerror = () => {
      reject(new Error(`Script Onload Error`));
    };

    document.getElementsByTagName('head')[0].appendChild(_scriptTag);
  });
};

/**
 * className 병합
 * @param classNameStringArray
 * @returns
 */
export const classNameMerge = (
  classNameStringArray: Array<string | undefined | null>,
): any => {
  if (Array.isArray(classNameStringArray)) {
    return classNameStringArray
      .filter((item) => {
        return item !== (null || '');
      })
      .join(' ');
  }
  return '';
};

/**
 * HEX TO RGBA
 */

export const hexToRGBA = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * 파일 크기 -> bytes로 변환
 */

export const sizeToBytes = (number: number, unit: string): number => {
  const size = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const k = 1024;
  const i = size.indexOf(unit.toUpperCase());
  return k ** i * number;
};

/**
 * Check Mintor
 */
export const isMintor = (roles: Array<string>) => {
  if (!roles || roles.length === 0) return false;

  return roles.includes(process.env.REACT_APP_MINTOR_ROLE as string);
};
