import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

// MUI
import { Container, AppBar, Box, Toolbar, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

// jotai
import { useSetAtom } from 'jotai';

// atoms
import { isShowSideMenu as atomIsShowSideMenu } from '@src/atoms/common/sideMenu';

// styles
import * as S from '@src/layout/CommonHeader/styles';

// images
import LogoImage from '@src/static/images/layout/header/1409-logo.png';

// Header Component
import ProfileMenu from '@src/components/CommonHeader/ProfileMenu';

// Common Component
import CommonLink from '@src/components/atoms/Link';

// Route
import { routerRegex } from '@src/route/routeConfig';

export const HEADER_HEIGHT = '64px';
const CommonHeader = () => {
  const setIsShowSideMenu = useSetAtom(atomIsShowSideMenu);
  return (
    <S.CommonHeader className="CommonHeader">
      <Container
        maxWidth="xxl"
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={{ flexGrow: 1, boxShadow: 0 }}>
          <AppBar
            position="relative"
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 0,
            }}
          >
            <Toolbar
              sx={{
                minHeight: {
                  xs: HEADER_HEIGHT,
                  sm: HEADER_HEIGHT,
                  md: HEADER_HEIGHT,
                  lg: HEADER_HEIGHT,
                  xl: HEADER_HEIGHT,
                  xxl: HEADER_HEIGHT,
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => setIsShowSideMenu((value) => !value)}
              >
                <MenuIcon
                  color="primary"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                  }}
                />
              </IconButton>
              <CommonLink<RouterLinkProps, typeof RouterLink>
                component={RouterLink}
                to={routerRegex.main.path}
                sx={{
                  fontSize: 0,
                }}
              >
                <S.HeaderLogoImage src={LogoImage} alt="1409 Logo" />
              </CommonLink>
              <Box sx={{ flexGrow: 1 }} />
              <ProfileMenu />
            </Toolbar>
          </AppBar>
        </Box>
      </Container>
    </S.CommonHeader>
  );
};

export default CommonHeader;
