// MUI
import { Box } from '@mui/material';

// Common Component
import CommonCircleProgress from '@src/components/atoms/CircleProgress';

// styles
import * as S from '@src/components/molecules/PageLoading/styles';

const PageLoading = () => {
  return (
    <Box className="PageLoading" sx={S.PageLoadingStyle}>
      <Box>
        <CommonCircleProgress size="10rem" />
      </Box>
    </Box>
  );
};

export default PageLoading;
