import {
  useLocation,
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

// MUI
import { Container, Box, Typography, List, ListItem } from '@mui/material';

// Images
import LogoImage from '@src/static/images/layout/footer/1409-logo.png';

// Common Component
import CommonLink from '@src/components/atoms/Link';

// Route
import { routerRegex } from '@src/route/routeConfig';

// styles
import * as S from '@src/layout/CommonFooter/styles';
import { common } from '@mui/material/colors';

const CommonFooter = (props: any) => {
  return (
    <S.CommonFooter
      className="CommonFooter"
      sx={{
        backgroundColor: `${
          useLocation().pathname === '/' ? '#000' : '#BAC49E'
        }`,
      }}
    >
      <Container
        maxWidth="xxl"
        sx={{
          p: 3,
        }}
      >
        <Box
          sx={{
            ...S.FooterInnerStyle,
            justifyContent: 'flex-start',
          }}
        >
          <CommonLink<RouterLinkProps, typeof RouterLink>
            component={RouterLink}
            to={routerRegex.main.path}
          >
            <S.FooterLogoImage src={LogoImage} alt="Footer 1409 Logo" />
          </CommonLink>
        </Box>
        <Box
          sx={{
            ...S.FooterInnerStyle,
            justifyContent: 'flex-end',
          }}
        >
          <List
            sx={{
              ...S.FooterLinkList,
            }}
          >
            <ListItem
              sx={{
                ...S.FooterLinkListItem,
              }}
            >
              <CommonLink<RouterLinkProps, typeof RouterLink>
                component={RouterLink}
                to={routerRegex.privacy.path}
                sx={{
                  fontSize: 0,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={(theme) => ({
                    fontWeight: 500,
                    color: theme.palette.primary.contrastText,
                    fontSize: '1.6rem',
                  })}
                >
                  개인정보처리방침
                </Typography>
              </CommonLink>
            </ListItem>
            <ListItem
              sx={{
                ...S.FooterLinkListItem,
                ml: 4,
              }}
            >
              <CommonLink<RouterLinkProps, typeof RouterLink>
                component={RouterLink}
                to={routerRegex.serviceTerms.path}
                sx={{
                  fontSize: 0,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={(theme) => ({
                    fontWeight: 500,
                    color: theme.palette.primary.contrastText,
                    fontSize: '1.6rem',
                  })}
                >
                  서비스이용약관
                </Typography>
              </CommonLink>
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            ...S.FooterInnerStyle,
            justifyContent: 'flex-end',
            mt: 3,
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              fontSize: '1.6rem',
            })}
          >
            문의처 | 문의처이메일
          </Typography>
        </Box>
        <Box
          sx={{
            ...S.FooterInnerStyle,
            justifyContent: 'flex-end',
            mt: 3,
          }}
        >
          <Typography
            variant="overline"
            component="span"
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              fontSize: '1.2rem',
              textAlign: 'right',
            })}
          >
            COPYRIGHT(c)Province of KEYPLAY. ALL RIGHT RESERVED.
          </Typography>
        </Box>
      </Container>
    </S.CommonFooter>
  );
};

export default CommonFooter;
