import { atom } from 'jotai';

/**
 * SideMenu ON/OFF
 */
export const isShowSideMenu = atom<boolean>(false);

/**
 * Language Trigger
 */
export const isFoldLanguageList = atom<boolean>(false);

/**
 * Profile Menu
 */
export const isShowProfileMenu = atom<boolean>(false);
