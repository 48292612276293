import { useEffect, Suspense, useRef } from 'react';
import { MUI_THEME } from '@src/config/muiConfig';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useSetAtom } from 'jotai';
import { ScrollRestoration } from 'react-router-dom';

// MUI
import { CssBaseline } from '@mui/material';

// Layout
import CommonLayout from '@src/layout/CommonMain';

// Common Component
import Modal from '@src/components/common/Modal';
import ScrollToTop from '@src/components/ScrollToTop';

// atom
import {
  asyncSetAuthInfoUser as actionAuthInfoUser,
  asyncSetAuthInfoIsVerification as actionAuthInfoIsVerification,
} from '@src/atoms/user';

/**
 * Declare Global
 */
declare global {
  interface Window {
    Kakao: any;
  }
}

const THEME = createTheme(MUI_THEME);

const Root = () => {
  const appRef = useRef(null);
  const setAuthInfoUser = useSetAtom(actionAuthInfoUser);
  const setAuthInfoIsVerification = useSetAtom(actionAuthInfoIsVerification);

  useEffect(() => {
    const { Kakao } = window;
    if (Kakao && !Kakao.isInitialized())
      Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_API_KEY);

    // User Info Initialize
    setAuthInfoUser();
    setAuthInfoIsVerification();
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      {/* 전역 스타일 */}
      <CssBaseline />
      {/* !전역 스타일 */}
      <RootContainer className="RootContainer" ref={appRef}>
        {/* Router Middlewapre */}
        <ScrollToTop />
        {/* !-- Router Middlewapre */}
        <ScrollRestoration />
        <CommonLayout />
        <Suspense fallback={null}>
          <Modal container={appRef} />
        </Suspense>
      </RootContainer>
    </ThemeProvider>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

export default Root;
