import { type IDownloadInfo, type TLikeInfo } from '@src/atoms/detail';

import { axiosInstance as request } from '@src/lib/axios/apiClient';

export const getNftDetail = (worksId: string) => {
  return request.get(`/nft/token/${worksId}`, {
    withCredentials: true,
  });
};
export const getTransferInfo = (_id: string) => {
  return request.get(`/nft/${_id}/transfer-info`);
};
export const postLike = (worksId?: string) => {
  return request.post<TLikeInfo>(`/nft/token/${worksId}/like`);
};
export const deleteLike = (worksId?: string) => {
  return request.delete(`/nft/token/${worksId}/like`);
};
export const getDownloadReceipt = (worksId: string) => {
  return request.get(`download-receipt/my/token/${worksId}`);
};
export const getDownloadReceiptCount = (_id: string) => {
  return request.get(`download-receipt/dCount/${_id}`);
};

export const download = (
  downloadInfo: IDownloadInfo,
  controller: any,
  dispatch: any,
) => {
  const { _id, name, gk, mimetype } = downloadInfo;

  return request.get(
    `/file?id=${_id}&gk=${gk}&index=0&filename=${name}&mimetype=${mimetype}`,
    {
      onDownloadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;

        const percent = Math.floor((loaded * 100) / total);
        dispatch({ type: 'download', value: percent });
      },
      signal: controller.signal,
      responseType: 'blob',
      timeout: Number.MAX_SAFE_INTEGER,
    },
  );
};

export const getBuyableToken = (gId?: string) => {
  return request.get(`/nft/${gId}`);
};
