import { styled } from '@mui/system';
import { common } from '@mui/material/colors';

export const CommonHeader = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 9,
  backgroundColor: common.white,
});

export const HeaderLogoImage = styled('img')({
  marginLeft: '12px',
  height: '34px',
});
