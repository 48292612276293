// jotai
import { useAtomValue } from 'jotai';

// MUI
import { Drawer } from '@mui/material';

// atoms
import { isShowSideMenu as atomIsShowSideMenu } from '@src/atoms/common/sideMenu';

// Component
import CommonSearchInput from '@src/components/molecules/SearchInput';
import CommonNavItemList from '@src/components/molecules/NavItemList';
import LanguageList from '@src/components/molecules/LanguageList';

interface ICommonSideMenyProps {
  positionContainer?: HTMLDivElement | null;
}
const CommonSideMenu = ({ positionContainer }: ICommonSideMenyProps) => {
  const isShowSideMenu = useAtomValue(atomIsShowSideMenu);

  return (
    <Drawer
      className="CommonSideMenu"
      anchor="left"
      variant="persistent"
      open={isShowSideMenu}
      PaperProps={{
        elevation: 0,
        sx: (theme) => ({
          top: '63px',
          width: '256px',
          boxSizing: 'border-box',
          borderRight: 'none',
          py: 3,
          px: 2,
          // [theme.breakpoints.up('md')]: {
          //   position: 'relative',
          //   top: 0,
          //   boxSizing: 'border-box',
          // },
        }),
      }}
    >
      <CommonSearchInput />
      <CommonNavItemList />
      <LanguageList />
    </Drawer>
  );
};

export default CommonSideMenu;
